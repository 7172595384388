@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
    background-color: #f8f9fe;
    font-family: Roboto,Arial,helvetica !important;
    font-size: 0.9rem !important;
    font-weight: 500;
}

.navbar-vertical.navbar-expand-md.fixed-left {
    border-width: 0 !important;
}

.admin-login {
    background: $primary;
    color: #ffffff;
    height: 100vh;
}

.navbar-light {
    background-color: #fff !important;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.06) !important;
}


.navbar {
    padding: 0rem 1rem !important;
    height: 66px;
    border-bottom: 1px solid #00000012;

    .active {
        a {
            color: $primary !important;
        }
        border-bottom: 2px solid $primary !important;
    }
}

.btn {
    padding: 0.425rem 1.25rem !important;
    border-radius: 100px !important;
}

.btn-lg {
    padding: 15px 38px !important;
}

.btn-primary-outline {
    background-color: transparent!important;
    border: 1px solid $primary!important;
    color: $primary !important;
}

.btn-dark {
    background-color: #1a252f !important;
    border-color: #1a252f !important;
}

.alert-dismissible .close {
    top: 30px !important;
}

.product-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
}
.product-uploader .el-upload:hover {
    border-color: $primary;
}

.el-upload--picture-card:hover, .el-upload:focus {
    border-color: $primary !important;
    color: $primary !important;
}

.product-admin-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.navbar-vertical .navbar-nav .nav-link {
    font-size: 0.84rem !important;
    font-weight: 500 !important;
}
.nav-link {
    font-size: 0.83rem !important;
    font-weight: 500 !important;
}

.product {
    &__categories {
        margin-top: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        overflow-x: auto;
        &--item {
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
            }
            width: 80px;
            min-width: 80px;
            padding: 10px 5px;
            border-radius: 10px;
            text-align: center;
            background-color: #e7e7e9;
            color: #000;
            margin: 10px;
            transition: all .2s ease-in-out;
            border: 1px solid transparent;
            img {
                width: 40%;
                object-fit: cover;

            }
            h6 {
                font-size: 11px;
                margin-top: 5px;
                margin-bottom: 0px;
            }

            &__active {
                background-color: #fff;
                box-shadow: 0 14px 20px -12px rgba(0,0,0,0.2) !important;
                border: 1px solid $primary;
            }

            &:hover {
                background-color: #fff;
                box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.06);
                border: 1px solid $primary;
            }
        }
    }

    .card {
        box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.06) !important;
        border-radius: 8px !important;
        border: 1px solid transparent;
        transition: all .2s ease-in-out;
        &:hover {
            border: 1px solid $primary;
            box-shadow: 0 14px 20px -12px rgba(0,0,0,0.2) !important;
        }
    }

    &__item {
        margin-bottom: 3rem;
        &--img {
            margin-top: -3rem;
            margin-bottom: 1rem;
            width: 100%;
            height: 150px;
            border-radius: 10px;
            object-fit: cover;
        }
        &--title {

        }

        &--content {
            font-size: 13px !important;
            color: #1a202e;
            font-weight: 400;
            p {
                font-size: 13px !important;
                color: #1a202e;
                font-weight: 400;
            }

        }

        &--add {
            width: 25px;
            height: 25px;
            border-radius: 100%;
            background-color: transparent;
            border: 2px solid #fff;
            color: #fff;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .2s ease-in-out;
            &:hover {
                background-color: transparent;
            }
        }

        &--qty {
            font-size: 15px;
            font-weight: 600;
            margin: 0px 15px;
        }
    }
}

.footer {
    width: 100%;
    padding: 1.5rem 2.5rem !important;
    .copyright {
        font-size: 1rem !important;
    }
    &__social {
        &--item {
            padding: 0.25rem 0.2rem !important;
            margin: 5px;
            svg {
                fill: #8898aa !important;
            }
            display: flex !important;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 10px;
            background-color: rgba(#8898aa, 0.2);
        }
    }
    &--info {
        text-align: right;
        &__total {
            color: #fff;
            font-size: 30px;
        }
    }
}

label {
    font-size: 13px !important;
}

.booking {
    margin-top: 2rem;
    &__remove-time {
        cursor: pointer;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        display: flex;
        justify-content:center;
        align-items: center;
        background-color: rgba(#bb2546, 0.2);
        svg {
            fill: #bb2546;
        }
    }
    &__modal {
        .el-dialog__body {
            padding: 10px 0px 0px 0px !important;
        }
        .el-tabs--border-card {
            box-shadow: none !important;
            border: none !important;
        }
        .el-tabs__nav  {
            display: flex;
            justify-content: space-around;
            width: 100% !important;
        }

        .is_disable {
            h4 {
                color: rgba(#32325d, 0.3) !important;
            }

            h2 {
                color: rgba(#32325d, 0.3) !important;
            }
        }
        .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-disabled {
            h4 {
                color: rgba(#32325d, 0.3) !important;
            }

            h2 {
                color: rgba(#32325d, 0.3) !important;
            }
        }
        .el-tabs__item {
            padding: 10px 20px !important;
            height: auto !important;
            width: 100% !important;
           
        }

        .el-tabs__nav-wrap {
            margin-bottom: -2px;
        }

        &--collection {
            .el-tabs--border-card>.el-tabs__header {
                background-color: rgba(#38baf1, 0.2) !important;
                border-bottom: 2px solid #38baf1 !important;
            }

            .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
                border-right: 2px solid #38baf1 !important;
                border-left: 2px solid #38baf1 !important;
                border-top: 3px solid #38baf1 !important;
            }

            &__item {
                cursor: pointer;
                padding: 10px;
                border-radius: 5px;
                background-color: #F5F7FA;
                border: 1px solid #E4E7ED;
                transition: all .2s ease-in-out;
                h4 {
                    font-size: 13px;
                }

                &:hover {
                    background-color: rgba(#38baf1, 0.1) !important;
                    border: 1px solid #38baf1 !important;
                }

                &--active {
                    background-color: rgba(#38baf1, 0.1) !important;
                    border: 1px solid #38baf1 !important;
                }
            }
            
        }

        &--delivery {
            .el-tabs--border-card>.el-tabs__header {
                background-color: rgba(#70cf4a, 0.2) !important;
                border-bottom: 2px solid #70cf4a !important;
            }

            .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
                border-right:2px solid #70cf4a !important;
                border-left: 2px solid #70cf4a !important;
                border-top: 3px solid #70cf4a !important;
            }

            &__item {
                padding: 10px;
                border-radius: 5px;
                background-color: #F5F7FA;
                border: 1px solid #E4E7ED;
                transition: all .2s ease-in-out;
                h4 {
                    font-size: 13px;
                }

                &:hover {
                    background-color: rgba(#70cf4a, 0.1) !important;
                    border: 1px solid #70cf4a !important;
                }

                &--active {
                    background-color: rgba(#70cf4a, 0.1) !important;
                    border: 1px solid #70cf4a !important;
                }
            }
            
        }
    }

    &__footer {
        width: 100%;
        box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.06) !important;
        border-radius: 8px !important;
        background-color: rgba(211, 215, 222, 0.2) !important;
        border: none !important;
        padding: 2.5rem;
    }
    &__header {
        margin-bottom: 2rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__content {
        margin-bottom: 2rem;
        p {
            font-size: 14px;
            font-weight: 400;
        }

        &--carts {
            img {
                width: 50px;
                height: 60px;
                border-radius: 10px;
                object-fit: cover;
            }

            span {
                font-size: 15px;
                font-weight: 500;
            }

            h5 {
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 0px;
            }

            &__item {
                padding: 15px 0px;
                border-bottom: 1px solid  rgba(0, 0, 0, 0.05);
                &--icon {
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                    font-size: 15px;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(0, 0, 0, 0.1);
                    svg {
                        fill: rgba(0, 0, 0, 0.5);
                    }

                    &:hover {
                        background-color: $primary;
                        svg {
                            fill: #fff;
                        }
                    }
                }
            }
        }

        &--total {
            &__item {
                padding: 10px 0px 5px 0px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);
                h1 {
                    font-size: 18px !important;
                }

                h3 {
                    font-size: 15px !important;
                }
            }
        }

        &--collection {
            border: 2px solid #38baf1;
            background-color: rgba(#38baf1, 0.1);
            border-radius: 5px;
            padding: 10px;
            svg {
                fill: #38baf1;
            }

            h2 {
                font-size: 16px;
                color: #38baf1;
            }

            h4 {
                font-size: 14px;
                color: #32325d;
            }

            h5 {
                font-size: 13px;
                color: #38baf1;
            }
        }

        &--delivery {
            border: 2px solid #70cf4a;
            background-color: rgba(#70cf4a, 0.1);
            border-radius: 5px;
            padding: 10px;
            svg {
                fill: #70cf4a;
            }

            h2 {
                font-size: 16px;
                color: #70cf4a;
            }

            h4 {
                font-size: 14px;
                color: #32325d;
            }

            h5 {
                font-size: 13px;
                color:  #70cf4a;
            }
        }
    }
}

.card {
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.06);
    border-radius: 8px !important;
    border: 1px solid transparent;
   
}

.card-dark {
    background-color: rgba(211, 215, 222, 0.2) !important;
    border: none !important;
}

.checkout {

    &__header {
        margin-bottom: 2rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    &__content {
        &--notify {
            margin-bottom: 2rem;
            padding: 15px;
            background-color: rgba(16, 150, 76, 0.2);
            border-radius: 10px;
            img {
                width: 55px;
                object-fit: cover;
            }

            p {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 0px;
            }
        }
    }
    &__payment {
        &--dark {
            background: #232526 !important; /* fallback for old browsers */
            background: -webkit-linear-gradient(to left, #232526, #414345) !important; /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient(to left, #232526, #414345) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            color: #fff !important;
        }

        &__header {
            margin-bottom: 1rem;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            h2 {
                color: #fff !important;
            }
        }

        &__content {
            p {
                font-size: 13px;
                color: rgba(255, 255, 255, 0.5);
            }
            label {
                color: rgba(255, 255, 255, 0.5) !important;
            }
        }

        .StripeElement {
            box-sizing: border-box;
    
            height: 40px;
    
            padding: 10px 12px;
    
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            background-color: rgba(255, 255, 255, 0.1);
    
            // box-shadow: 0 1px 3px 0 #e6ebf1;
            -webkit-transition: box-shadow 150ms ease;
            transition: box-shadow 150ms ease;
            }
    
        .StripeElement--focus {
            border: 1px solid $primary;
            // box-shadow: 0 1px 3px 0 $primary;
        }
    
        .StripeElement--invalid {
            border-color: #fa755a;
        }
    
        .StripeElement--webkit-autofill {
            background-color: #fefde5 !important;
        }
    }
}

.order {
    .card-body {
        padding: 0px !important;
    }
    &__products {
        &--item {
            img {
                width: 30px;
                height: 40px;
                object-fit: cover;
                border-radius: 5px;
            }
        }
    }
}

.booking-success {
    &__img {
        margin-top: 7rem;
        width: 300px;
    }
    h2 {
        font-size: 30px;
        margin-top: 1rem;
    }
}

.booking {
    .form-control {
        height: calc(1.5em + 1.25rem + 2px) !important;
        background-color: transparent !important;
    }
}

.top-nav {
    background-color: $primary-dark;
    color: #fff;
    padding: 15px 25px;
    width: 100%;

    &__menu {
        display: flex;
        align-items: center;
        &--item {
            padding: 0px 20px;
            color: rgba(255, 255, 255, 0.9);
            font-size: 13px;
            transition: all .2s ease-in-out;
            &:not(:last-child) {
                border-right: 1px solid #fff;
            }
            &:hover {
                color: #fff;
            }
        }
    }
}

.header {
    min-height: 500px;
    background-color: #fff;
    width: 100%;
    // padding: 50px 0px;
    position: relative;
    overflow: hidden;
    &__overlay{
        position: absolute;
        top: 0;
        right: -50%;
        display: none;
        img {
            width: 400px !important;
        }
    }
    &__title {
        margin-top: 5rem;
        margin-bottom: 0px;
        color: $primary;
        font-size: 55px;
        font-weight: 500;
    }
    &__sub-title {
        color: $primary;
        font-size: 35px;
        font-weight: 400;
        margin-bottom: 2rem;
    }
    img {
        width: 110px;
    }

    &__img {
        width: 100% !important;
        position: absolute;
        top: 33px;
    }
}

.how-it-work {
    background-color:$primary-dark;
    padding: 50px 0px;
    &__title {
        color: #fff;
        font-size: 30px;
        margin-bottom: 4rem;
        text-align: center;
    }

    &__item {
        text-align:center;
        img {
            width: 150px;
        }

        h4 {
            color: #fff;
            font-size: 16px
        }
    }
}

.order {
    padding: 50px 0px;
    &__title {
        color: #414141;
        font-size: 30px;
        margin-bottom: 4rem;
        text-align: center;
    }

}

.navbar-vertical.navbar-expand-md {
    height: auto !important;
}

.nav-pills .nav-link {
    background-color: #e7e7e9 !important;
    color: #000 !important;
    box-shadow: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span {
        margin-top: 10px;
        font-size: 15px;
    }

    img {
        width: 40%;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #000 !important;
    background-color: #fff !important;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%) !important;
}

.card-black {
    background-color: #4a4a4a !important;
    border: 1px solid #fff !important;
    border-radius: 10px !important;
    overflow: hidden;
    h4 {  
        font-size: 16px !important;
        color: #fff !important;
        margin-bottom: 0px !important;
    }
}

.cart {
    cursor: pointer;
    position: relative;
    svg {
        fill: rgba(0, 0, 0, 0.5);
    }
    &__count {
        position: absolute;
        top: -10px;
        right: -13px;
        width: 20px;
        height: 20px;
        font-size: 12px;
        color: #fff;
        background-color: #ff202b;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

}

.faq {
    padding: 50px 0px 80px 0px;
    background-color:#fff !important;
    &__title {
        color: #414141;
        font-size: 30px;
        margin-bottom: 4rem;
        text-align: center;
    }

}

.services {
    padding: 50px 0px 80px 0px;
    background-color:$primary !important;
    &__title {
        color: #fff;
        font-size: 30px;
        margin-bottom: 4rem;
        text-align: center;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
            width: 50%;
        }

        &--info {
            h2 {
                color:rgba(0, 0, 0, 0.7);
                font-size: 16px;
            }

            p {
                color:rgba(0, 0, 0, 0.5);
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
}

.contact {
    padding: 50px 0px 80px 0px;
    background-color: $primary-dark !important;
    &__title {
        color: #fff;
        font-size: 30px;
        margin-bottom: 4rem;
        text-align: center;
    }

    &__info {
        h2 {
            color: #fff;
            font-size: 30px;
            margin-bottom: 2rem;
        }

        p {
            color: rgba(255, 255, 255, 0.7);
            font-size: 15px;
            margin-bottom: 2rem;
        }

        &--list {
            &__item {
                &--icon {
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color:rgba(#fff, 0.2);
                    svg {
                        fill: #fff;
                    }
                }

                h4 {
                    font-size: 18px;
                    color: #fff;
                }
            }
        }
    }

}

.google-app-play-2 {
    display: none;
}

.cart-mobile {
    display: none;
}

.el-drawer__body {
    overflow: auto;
}

.el-collapse-item__header {
    font-size: 16px !important;
    background-color: transparent !important;
    border-bottom: 1px solid rgba(#303133, 0.2) !important;
}

.el-collapse-item__content {
    padding-top: 25px !important;
    font-size: 15px !important;
}

.el-collapse {
    border-top: 1px solid rgba(#303133, 0.2) !important;
    border-bottom: 1px solid rgba(#303133, 0.2) !important;
}

.el-collapse-item__wrap {
    background-color: transparent !important;
    border-bottom: 1px solid rgba(#303133, 0.2) !important;
}

.mobile-nav {
    &__list {
        margin-top: 1rem;
        list-style: none;
        display: flex;
        flex-direction: column;
        &--item {
            cursor: pointer;
            padding: 15px 30px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            font-size: 16px;
            color: rgba(0, 0, 0, 0.8);

             &:hover {
                 background-color: rgba($primary, 0.1);
             }
        }
    }
}

// @media (min-width: 992px)


@media only screen and (max-width: 992px) {
   .header__img {
       display: none !important;
   }

   .header__overlay {
       display: block;
   }

   .el-drawer {
        width: 40% !important;

    }

    .el-dialog {
        width: 50% !important;
    }

    .cart-mobile {
        display: block !important;
    }
   
}

@media only screen and (max-width: 768px) {
    .header__title {
        margin-top: 2rem;
    }

    .google-app-play-1 {
        display: none !important;
    }

    .google-app-play-2 {
        display: block !important;
        width: 60% !important;
        margin: 20px 0px;
    }

    .el-drawer {
        width: 60% !important;
    }

    .el-dialog {
        width: 70% !important;
    }
 }

 @media only screen and (max-width: 576px) {
    .header__overlay {
        display: none !important;
    }

    .top-nav {
        display: none;
    }
    
    .el-drawer {
        width: 80% !important;
    }

    .el-dialog {
        width: 90% !important;
    }
}

    