// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
// Variables
@import 'variables';
@import 'custom';
@import 'argon';
// Bootstrap
@import '~bootstrap/scss/bootstrap';

body {
    font-family: 'Roboto', sans-serif !important;
}
